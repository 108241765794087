import React from "react";

const FooterAboutMe = () => (
  <div>
    <p className="footer-aboutMe">
      MaxDron, oferujemy kompleksowe usługi z zakresu fotografii oraz filmowania
      wszelkiego rodzaju obiektów z powietrza. Posiadamy najnowszego rodzaju
      sprzęt na rynku który pozwala uzyskiwać materiał w najwyższej jakości
      obrazu. Wykonywanie zdjęć o rozdzielczości 12 Mpix oraz nagrywanie obrazu
      w jakości 4K. Zasięg naszych usług jest praktycznie nieograniczony,
      działamy głównie na terenie całego Trójmiasta oraz w granicach województwa
      pomorskiego. Zlecenie wykonujmy możliwie szybko i w konkurencyjnych cenach
      po uzgodnieniu szczegółów z klientem. Angażując się w każdy projekt
      proponujemy ciekawe ujęcia i rozwiązania przy realizacji każdego projektu.
      Szybkość realizacji to nasza mocna strona, istnieje możliwość otrzymania
      materiału nawet na drugi dzień po wykonaniu zlecenia. Zdjęcia oraz
      nagrania przekazujemy w surowym formacie lub po odpowiedniej obróbce.
    </p>
    <p className="footer-aboutMe-two">Zapraszamy do kontaktu.</p>
  </div>
);

export default FooterAboutMe;
