import project1 from "../img/Services/Project1.jpg";
import project2 from "../img/Services/Project2.jpg";
import project3 from "../img/Services/Project3.jpg";
import project4 from "../img/Services/Project4.jpg";
import project5 from "../img/Services/Project5.jpg";
import project6 from "../img/Services/Project6.jpg";
import project7 from "../img/Services/Project7.jpg";
import project8 from "../img/Services/Project8.jpg";
import project9 from "../img/Services/Project9.jpg";

const ServicesPhotoRow = [
  {
    photo: project1,
    title: "filmy poklatkowe timelapse",
    description:
      "Realizujemy długookresowe filmy poklatkowe 'Timelapse', które doskonale obrazują cykl zmian zachodzących na budowie.",
  },
  {
    photo: project2,
    title: "usługi dla developerów, architektów i firm budowlanych",
    description: "Panoramy przyszłych kontygnacji || Postęp budowy || Dokumentacja budowy || Monitoring budowy",
  },
  {
    photo: project3,
    title: "obiektów na sprzedaż",
    description: "Filmy i zdjęcia sprzedażowe budynków, działek, itp.",
  },
  {
    photo: project4,
    title: "otoczenia nieruchomości",
    description: "Fotografia z drona umożliwia pokazanie walorów działek oraz nieruchomości",
  },
  {
    photo: project5,
    title: "do wizualizacji",
    description: "Fotografie pod komputerową wizualizacje obiektów",
  },
  {
    photo: project6,
    title: "architektury i krajobrazów",
    description: "Fotografie na potrzeby reklam || Fotografie do prezentacji || Inne",
  },
  {
    photo: project7,
    title: "monitoringu obiektów",
    description: "Fotografie z inspekcji wszelkiego rodzajów obiektów np. z trudno dostępnych miejsc",
  },
  {
    photo: project8,
    title: "operacji specjalnych",
    description: "Relacje ze szczególnych wydarzeń",
  },
  {
    photo: project9,
    title: "wydarzeń",
    description: "Relacje z eventów",
  },
];

export default ServicesPhotoRow;
