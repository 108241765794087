import React from "react";
import HeaderMovie from "./HeaderComponents/HeaderMovie";

const Header = () => (
  <div>
    <HeaderMovie />
  </div>
);

export default Header;
