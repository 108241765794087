const ServicesMovieRow = [
    {
        video: "jUygnVNGE00",
        title: "Olivia Business Centre - Olivia Star 180m (06.2018) MaxDron"
    },
    {
        video: "J339D5ygdcg",
        title: "Przykładowe inspekcje postępów prac budowlanych"
    },
    {
        video: "NZjHgWjUU8Y",
        title: "Olivia Business Centre - Olivia Star 180m (03.2018) MaxDron"
    },
    {
        video: "jilRUdls3eM",
        title: "Przykładowa efektowna prezentacja inwestycji"
    },
    {
        video: "_mUih6Vr5uI",
        title: "Wycieczkowce w Gdyni sezon 2018 4K - MaxDron"
    }
];
export default ServicesMovieRow